import {
    Box,
    FormHelperText,
    TextField,
    Typography,
    Checkbox,
    Button,
    IconButton,
    InputAdornment,
    OutlinedInput,
    List,
    ListItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextareaAutosize,
    Select,
    Menu,
    MenuItem,
    FormControl,
    RadioGroup,
    Radio,
    Tabs,
    Tab,
    Switch,
    styled,
    Modal,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TablePagination,
    InputLabel,
    FilledInput,
    Tooltip,
    Grid,
    FormGroup,
    FormControlLabel,
    Accordion,
    AccordionSummary,
    AccordionDetails,
  } from "@mui/material";
  import Png from "../assets/Png";
  import Svg from "../assets/Png";
  import Visibility from "@mui/icons-material/Visibility";
  import VisibilityOff from "@mui/icons-material/VisibilityOff";
  
  export default {
    Png,
    Svg,
    Box,
    FormHelperText,
    TextField,
    Typography,
    Checkbox,
    Button,
    IconButton,
    InputAdornment,
    OutlinedInput,
    List,
    ListItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextareaAutosize,
    Select,
    Menu,
    MenuItem,
    FormControl,
    RadioGroup,
    Radio,
    Tabs,
    Tab,
    Switch,
    styled,
    Modal,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TablePagination,
    InputLabel,
    FilledInput,
    Tooltip,
    Grid,
    FormGroup,
    FormControlLabel,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    VisibilityOff,
    Visibility,
  };
  