// login ypwm
import React, { useState } from "react";
import Index from "../../Index";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { LoginAdmin } from "../../../redux/features/slices/admin/AdminService";
import { CircularProgress } from "@mui/material";
import { getAdminToken } from "../../../redux/features/slices/admin/AdminSlice";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    email: "",
    password: "",
  };

  // password
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  // validation Schema
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter valid email")
      .matches(
        /^[a-zA-Z][a-zA-Z0-9]*(?:\.[a-zA-Z]+)*@[a-zA-Z]+\.[a-zA-Z]{2,4}$/,
        "Please enter valid email"
      )
      .required("Please enter an email")
      .max(255, "Email must be at most 255 characters"),

    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,15}$/,
        "Password length should be minimum 8 and maximum 15 character including one capital letter, one small letter, one number, and one special character, ex. 99@Inchroad"
      ),
  });

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    const formData = new URLSearchParams();
    formData.append("email", values.email);
    formData.append("password", values.password);
    dispatch(LoginAdmin(values))
      .then((res) => {
        console.log(res, 46);
        dispatch(getAdminToken(res?.payload?.data?.token));
        if (res?.payload?.status === 200) {
          navigate("admin");
        }

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  return (
    <>
      <Index.Box className="login-main-content">
        <Index.Box className="grid-row login-row">
          <Index.Box sx={{ width: 1 }} className="grid-main login-main">
            <Index.Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
              className="login-grid"
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 6",
                  lg: "span 8",
                }}
                className="grid-column login-column"
              >
                <Index.Box className="login-bg-content f-direction">
                  <img
                    src={Index.Png.yesPayLogo}
                    className="bg-login-vector"
                  ></img>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 6",
                  lg: "span 4",
                }}
                className="grid-column login-column login-user-form  "
              >
                <Index.Box className="admin-login-main-wrap">
                  <Index.Box className="admin-login-main">
                    <Index.Box className="auth-logo">
                      <img
                        src={Index.Png.yesPayLogo}
                        className="login-yesPay"
                        width={150}
                        height={80}
                      ></img>
                    </Index.Box>
                    <Index.Box className="admin-login-inner">
                      <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleFormSubmit}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <Index.Box className="">
                              <Index.Typography
                                component="h2"
                                variant="h2"
                                className="admin-wel-text"
                              >
                                {/* {visitedHomePage ? "Welcome back!" : "Welcome"} */}
                                Welcome
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="admin-sign-para common-para"
                              >
                                Please enter your credentials to Login!
                              </Index.Typography>
                              <Index.Box className="input-box input-box-admin">
                                <Index.FormHelperText className="form-lable">
                                  Email <span style={{ color: "red" }}>*</span>
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                  <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    name="email"
                                    className="form-control custom-control"
                                    onBlur={handleBlur}
                                    value={values.email}
                                    onChange={handleChange}
                                    helperText={touched.email && errors.email}
                                    error={Boolean(
                                      errors.email && touched.email
                                    )}
                                  />
                                </Index.Box>
                              </Index.Box>
                              <Index.Box className="input-box input-box-admin">
                                <Index.FormHelperText className="form-lable">
                                  Password{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </Index.FormHelperText>

                                <Index.Box className="form-group pass_group_main">
                                  <Index.OutlinedInput
                                    className="form-control custom_form_control"
                                    id="outlined-adornment-password"
                                    type={showPassword ? "text" : "password"}
                                    name="password"
                                    onBlur={handleBlur}
                                    value={values.password.trim()}
                                    onChange={handleChange}
                                    helperText={
                                      touched.password && errors.password
                                    }
                                    error={Boolean(
                                      errors.password && touched.password
                                    )}
                                    inputProps={{ maxlength: 15 }}
                                    endAdornment={
                                      <Index.InputAdornment
                                        position="end"
                                        className="pass_position"
                                      >
                                        <Index.IconButton
                                          className="icon_end_icon"
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowPassword}
                                          onMouseDown={handleMouseDownPassword}
                                          edge="end"
                                        >
                                          {!showPassword ? (
                                            <Index.VisibilityOff />
                                          ) : (
                                            <Index.Visibility />
                                          )}
                                        </Index.IconButton>
                                      </Index.InputAdornment>
                                    }
                                    label="Password"
                                  />
                                  {touched.password && errors.password && (
                                    <Index.FormHelperText error>
                                      {errors.password}
                                    </Index.FormHelperText>
                                  )}
                                </Index.Box>
                              </Index.Box>

                              <Index.Box className="flex-all forgot-row">
                                <Index.Box className="checkbox-main">
                                  <Index.FormControlLabel
                                    control={<Index.Checkbox />}
                                    label="Remember Me"
                                    className="checkbox-lable"
                                  />
                                </Index.Box>
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="admin-forgot-para common-para"
                                >
                                  <Link
                                    className="signup-redirect"
                                    to="/admin/forgot-password"
                                  >
                                    Forgot Password ?
                                  </Link>
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="btn-main login-btn-main primary-btn-main-comman">
                                <Index.Button
                                  className={`btn login-btn btn-dark-comman-main ${
                                    isLoading ? "disabled" : ""
                                  }`}
                                  type="submit"
                                  disableRipple
                                  disabled={isLoading}
                                >
                                  {!isLoading ? (
                                    ""
                                  ) : (
                                    <>
                                      <CircularProgress
                                        sx={{ color: "white", mr: 1 }}
                                        size={24}
                                      />
                                    </>
                                  )}
                                  Login
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          </form>
                        )}
                      </Formik>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
