import React, { useEffect, useState } from "react";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import Index from "../../../Index";

export default function AdminSetting() {
  const [loader, setLoader] = useState(false);
  const [inpval, setInpval] = useState({
    usdtAddress: "",
    application_link: "",
    coin_price: "",
    tree_plant_usdt: "",
    team_level_income: "",
    team_level_bonus: "",
    staking: "",
  });

  const initialValues = {
    usdtAddress: inpval?.usdtAddress ? inpval?.usdtAddress : "",
    application_link: inpval?.application_link ? inpval?.application_link : "",
    coin_price: inpval?.coin_price ? inpval?.coin_price : "",
    tree_plant_usdt: inpval?.tree_plant_usdt ? inpval?.tree_plant_usdt : "",
    team_level_income: inpval?.team_level_income
      ? inpval?.team_level_income
      : "",
    team_level_bonus: inpval?.team_level_bonus ? inpval?.team_level_bonus : "",
    staking: inpval?.staking ? inpval?.staking : "",
  };

  const SettingValidationSchema = Yup.object().shape({
    usdtAddress: Yup.string().required("Please enter USDT address"),
    coin_price: Yup.number().required("Please enter coin price"),
    tree_plant_usdt: Yup.number().required("Please enter tree plant USDT"),
    team_level_income: Yup.number().required("Please enter team level income"),
    team_level_bonus: Yup.number().required("Please enter team level bonus"),
    staking: Yup.number().required("Please enter staking"),
  });

  const getSettingDetails = () => {
    // setLoader(true);
    // DataService(token)
    //   .get(API.Admin.GET_SETTING)
    //   .then((res) => {
    //     setLoader(false);
    //     let data = res?.data?.data;
    //     dispatch(getSettingData(data));
    //     let obj = {
    //       usdtAddress: data?.wallet_address,
    //       application_link: data?.app_link,
    //       coin_price: data?.coin_price,
    //       tree_plant_usdt: data?.tree_plant,
    //       team_level_income: data?.team_level_income,
    //       team_level_bonus: data?.team_level_bonus,
    //       staking: data?.total_mining_supply,
    //     };
    //     setInpval(obj);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  useEffect(() => {
    getSettingDetails();
  }, []);

  const handleFormSubmit = async (values) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("wallet_address", values?.usdtAddress);
    urlencoded.append("app_link", values?.application_link);
    urlencoded.append("coin_price", values?.coin_price);
    urlencoded.append("tree_plant", values?.tree_plant_usdt);
    urlencoded.append("team_level_income", values?.team_level_income);
    urlencoded.append("team_level_bonus", values?.team_level_bonus);
    urlencoded.append("total_mining_supply", values?.staking);

    // await DataService(token)
    //   .post(API.Admin.ADMIN_SETTING, urlencoded)
    //   .then((res) => {
    //     if (res.status == 201) {
    //       Index.toast.success("Add Setting.");
    //     } else if (res.status == 200) {
    //       Index.toast.success("Update Setting.");
    //     }
    //     getSettingDetails();
    //   })
    //   .catch((error) => {
    //     Index.toast.error(error?.response?.data?.message);
    //   });
  };

  return (
    <div>
      <Index.Box className="custom-card bg-white">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={SettingValidationSchema}
          onSubmit={handleFormSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Index.Box className="dashboard-content  add-user-containt">
                <Index.Box className="dashboard-content">
                  <Index.Box className="user-head-title">
                    <Index.Typography
                      className="admin-page-title"
                      component="h2"
                      variant="h2"
                    >
                      Setting
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="setting-content">
                  <Index.Grid container spacing={2} className="grid-100">
                    <Index.Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      className="set-grid-modal"
                    >
                      <Index.Box className="input-box input-box-admin input-box-admin2 join-wait-input join-wait-input-set res-join-wait-input">
                        <Index.FormHelperText className="form-lable">
                          USD Deposit Address
                        </Index.FormHelperText>
                        <Index.Box className="flex-input">
                          <Index.Box className="form-group">
                            <Index.TextField
                              id=""
                              className="form-control"
                              value={values?.usdtAddress}
                              name="usdtAddress"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              helperText={
                                touched.usdtAddress && errors.usdtAddress
                              }
                              error={Boolean(
                                errors.usdtAddress && touched.usdtAddress
                              )}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      className="set-grid-modal"
                    >
                      <Index.Box className="input-box input-box-admin  join-wait-input join-wait-input-set res-join-wait-input">
                        <Index.FormHelperText className="form-lable">
                          Application Link
                        </Index.FormHelperText>
                        <Index.Box className="flex-input">
                          <Index.Box className="form-group">
                            <Index.TextField
                              id=""
                              className="form-control"
                              value={values?.application_link}
                              name="application_link"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              helperText={
                                touched.application_link &&
                                errors.application_link
                              }
                              error={Boolean(
                                errors.application_link &&
                                  touched.application_link
                              )}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      className="set-grid-modal"
                    >
                      <Index.Box className="input-box input-box-admin  join-wait-input join-wait-input-set res-join-wait-input join-field">
                        <Index.FormHelperText className="form-lable">
                          Coin Price
                        </Index.FormHelperText>
                        <Index.Box className="flex-input">
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id=""
                              className="form-control"
                              //   type="number"
                              value={values?.coin_price}
                              name="coin_price"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              helperText={
                                touched.coin_price && errors.coin_price
                              }
                              error={Boolean(
                                errors.coin_price && touched.coin_price
                              )}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      className="set-grid-modal"
                    >
                      <Index.Box className="input-box input-box-admin  join-wait-input join-wait-input-set res-join-wait-input">
                        <Index.FormHelperText className="form-lable">
                          USDT For Tree Plant
                        </Index.FormHelperText>
                        <Index.Box className="flex-input">
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id=""
                              className="form-control"
                              //   type="number"
                              value={values?.tree_plant_usdt}
                              name="tree_plant_usdt"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              helperText={
                                touched.tree_plant_usdt &&
                                errors.tree_plant_usdt
                              }
                              error={Boolean(
                                errors.tree_plant_usdt &&
                                  touched.tree_plant_usdt
                              )}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    {/* maximum distribution coin */}

                    <Index.Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      className="set-grid-modal"
                    >
                      <Index.Box className="input-box input-box-admin  join-wait-input join-wait-input-set res-join-wait-input">
                        <Index.FormHelperText className="form-lable">
                          Team Level Income
                        </Index.FormHelperText>
                        <Index.Box className="flex-input">
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id=""
                              //   type="number"
                              className="form-control"
                              value={values?.team_level_income}
                              name="team_level_income"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              helperText={
                                touched.team_level_income &&
                                errors.team_level_income
                              }
                              error={Boolean(
                                errors.team_level_income &&
                                  touched.team_level_income
                              )}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      className="set-grid-modal"
                    >
                      <Index.Box className="input-box input-box-admin  join-wait-input join-wait-input-set res-join-wait-input">
                        <Index.FormHelperText className="form-lable">
                          Team Level Bonus
                        </Index.FormHelperText>
                        <Index.Box className="flex-input">
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id=""
                              className="form-control"
                              //   type="number"
                              value={values?.team_level_bonus}
                              name="team_level_bonus"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              helperText={
                                touched.team_level_bonus &&
                                errors.team_level_bonus
                              }
                              error={Boolean(
                                errors.team_level_bonus &&
                                  touched.team_level_bonus
                              )}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      className="set-grid-modal"
                    >
                      <Index.Box className="input-box input-box-admin  join-wait-input join-wait-input-set res-join-wait-input">
                        <Index.FormHelperText className="form-lable">
                          Staking
                        </Index.FormHelperText>
                        <Index.Box className="flex-input">
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id=""
                              className="form-control"
                              //   type="number"
                              value={values?.staking}
                              name="staking"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              helperText={touched.staking && errors.staking}
                              error={Boolean(errors.staking && touched.staking)}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                  </Index.Grid>
                </Index.Box>
              </Index.Box>
              <Index.Box
                className="btn-main res-btn-main login-btn-main primary-btn-main-comman "
                mt={2}
              >
                <Index.Button
                  className="add-user-btn  btn-dark-comman-main "
                  disableRipple
                  type="button"
                  onClick={(e) => handleSubmit(e)}
                >
                  Update
                </Index.Button>
              </Index.Box>
            </Form>
          )}
        </Formik>
      </Index.Box>
    </div>
  );
}
