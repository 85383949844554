import "react-toastify/dist/ReactToastify.css";
import "./container/admin/auth/login.css";
import "./assets/style/global.css"
import "./component/admin/defaultLayout/defaultLayout.css";
import Routes from "./routes/Routes";
import Header from "./component/admin/defaultLayout/Header";
import Sidebar from "./component/admin/defaultLayout/Sidebar";
import { ToastContainer } from "react-toastify";

function App() {
  // {
  //   console.log = () => {};
  //   console.error = () => {};
  //   console.debug = () => {};
  // }
  return (
    <div className="App">
      <ToastContainer/>
      <Routes />
      {/* <Sidebar/> */}
    </div>
  );
}

export default App;
