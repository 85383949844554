import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { useLocation, useNavigate } from "react-router-dom";
import { DataService } from "../../../../config/DataService";
import { useSelector } from "react-redux";
import API from "../../../../config/API";
import { toast } from "react-toastify";

const ViewSelectedToken = () => {
  const token = useSelector((state) => state.admin.adminToken);
  const [tokenList, setTokenList] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state?.data;
  console.log(data, 46);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [searchVal, setSearchVal] = useState("");
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getViewToken = async () => {
    setLoader(true);
    const formData = new URLSearchParams();
    formData.append("id", data?.id);
    await DataService(token)
      .get(`${API.Admin.GET_VIEW_TOKEN}/${data?._id}`)
      .then((response) => {
        if (response.data.status == 200) {
          console.log(response?.data?.data, 852);
          setTokenList(response?.data?.data);
          setFilterData(response?.data?.data);
          setCount(response?.data?.data.length);

          setLoader(false);
        } else {
          setTimeout(() => setLoader(false), 2000);
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message);
      });
  };
  useEffect(() => {
    getViewToken();
  }, []);

  const userDisable = async (id) => {
    setLoader(true);
    const formData = new URLSearchParams();
    formData.append("id", id);
    await DataService(token)
      .post(API.Admin.TOKEN_ACTIVE_DEACTIVE, formData)
      .then((response) => {
        if (response.data.status == 200) {
          setTimeout(() => {
            toast.success(response.data.message);
            getViewToken();
          }, 700);
        }
      })
      .catch((error) => {});
  };

  const handleSearch = (event) => {
    const searchValue = event?.target?.value.toLowerCase().trim();

    const result = tokenList.filter((item) => {
      const nameIncludes = item?.name?.toLowerCase()?.includes(searchValue);

      const isPriceNumber =
        typeof item?.token_price === "number" && !isNaN(item?.token_price);
      const priceMatches =
        isPriceNumber && item?.token_price.toString().includes(searchValue);

      setSearchVal(searchValue);

      return nameIncludes || priceMatches;
    });

    setFilterData(result);
    setCount(result?.length);
    setPage(0);
  };

  return (
    <div style={{ position: "relative" }}>
      {loader && (
        <div>
          <Index.Box className="loader-main">
            <img src={Index.Png.loader} className="loader-img" />
          </Index.Box>
        </div>
      )}
      <div className={loader ? "loader-overlay" : ""}>
        <Index.Box className="dashboard-content bg-white add-user-container transaction-wrap">
          <Index.Box className="user-head-title">
            <Index.Box className="admin-dashboard-box-header-main">
              <Index.IconButton
                onClick={() => navigate("/admin/chainList")}
                className="admin-back-icon-main"
              >
                <Index.ArrowBackIcon className="admin-back-icon" />
              </Index.IconButton>
              <Index.Typography
                // className="admin-page-title user-list-page-title res-admin-page-title-set"
                // component="h2"
                // variant="h2"
                className="admin-page-title"
              >
                Token {data.name}
              </Index.Typography>
            </Index.Box>

            <Index.Box className="transaction-right">
              <Index.Box className="preasale-search">
                <Index.Box className="user-search-main cus-search">
                  <Index.Box className="user-search-box">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        value={searchVal}
                        placeholder="Search..."
                        onChange={(e) => handleSearch(e)}
                      />

                      <img
                        src={Index.Svg.search}
                        className="search-grey-img"
                        alt="search grey img"
                      ></img>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-dashboard-list-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="chain-token-table">
                    <Index.Box className="page-table-main table-design-main chain-token-table-main ">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 650 }}
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                No.
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                Name
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                Price
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                Action
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>

                          <Index.TableBody className="table-body">
                            {filterData.length > 0 ? (
                              filterData
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((row, index) => {
                                  const currentIndex =
                                    page * rowsPerPage + index + 1;
                                  return (
                                    <Index.TableRow
                                      key={index}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                      >
                                        {currentIndex}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                      >
                                        {row?.name ? row?.name : "-"}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                      >
                                        {row?.token_price
                                          ? row?.token_price
                                          : "-"}
                                      </Index.TableCell>
                                      <Index.IconButton>
                                        <Index.FormControlLabel
                                          control={
                                            <Index.IOSSwitch
                                              checked={row?.is_active === true}
                                              sx={{ m: 1 }}
                                              className="MuiSwitch-track-active"
                                              onClick={() => {
                                                userDisable(row?._id);
                                              }}
                                            />
                                          }
                                          className="switch-lable"
                                        />
                                      </Index.IconButton>
                                    </Index.TableRow>
                                  );
                                })
                            ) : (
                              <Index.TableRow>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                  colSpan={7}
                                >
                                  No Record found
                                </Index.TableCell>
                              </Index.TableRow>
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>{" "}
                  <Index.Box className="pagination-main">
                    <Index.TablePagination
                      component="div"
                      page={page}
                      // count={filterData?.length}
                      count={count}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[15, 25, 50]}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelDisplayedRows={(page) =>
                        `Records ${page.from} to ${
                          page.to === -1 ? page.count : page.to
                        } of ${page.count}`
                      }
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {/* <Index.Box className="admin-back-btn-main">
            <Index.Button
              onClick={() => navigate("/admin/chainList")}
              className="admin-back-btn-main"
            >
              <Index.ArrowBackIcon />
            </Index.Button>
          </Index.Box> */}
        </Index.Box>
      </div>
    </div>
  );
};

export default ViewSelectedToken;
