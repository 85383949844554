import fbcLogo from "./images/png/FBC_Logo.png";
import bgFbcLogo from "./images/png/bgFbcLogo.png";
import profile from "./images/png/profile.png";
import logout from "./images/png/logout.png";
import cancel from "./images/png/cancel.png";
import yesPayLogo from "./images/png/Yes_pay.png"
import spinner from "./images/gif/Spinner-5 (1).gif"
import loader from "./images/gif/loader.gif"
import token from "./images/png/token_png.png"
import settings from "./images/png/setting.png"
import transaction from "./images/png/transaction.png"
import edit from './images/png/edit.png'
const Png = {
  fbcLogo,
  bgFbcLogo,
  profile,
  logout,
  cancel,
  yesPayLogo,
  spinner,
  loader,
  settings,
  token,
  transaction,
  edit,
};

export default Png;
