import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Index from "../../../Index";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { DataService } from "../../../../config/DataService";
import API from "../../../../config/API";
import { useSelector } from "react-redux";

export default function ChainList() {
  const token = useSelector((state) => state.admin.adminToken);
  console.log(token, "adminToken");
  const [chainList, setChainList] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [loader, setLoader] = useState(false);
  const [count, setCount] = useState(0);
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getChainList = async () => {
    setLoader(true);
    await DataService(token)
      .get(API.Admin.GET_TOKEN_CATEGORY_LIST)
      .then((res) => {
        if (res?.data?.status === 200) {
          console.log(res, 60);
          setChainList(res?.data?.data);
          setCount(res?.data?.data.length);
          setFilterData(res?.data?.data);
          setLoader(false);
        } else {
          setTimeout(() => setLoader(false), 2000);
        }
      })
      .catch((error) => {
        console.log(error, 62);
      });
  };

  useEffect(() => {
    getChainList();
  }, []);
  // const handleImageUpload = async (e, id) => {
  //   const image = e.target.files[0];
  //   console.log(image, id, 57);
  //   const formData = new FormData();
  //   formData.append("id", id);
  //   formData.append("registerDocument", e.target.files[0]);
  //   await DataService(token)
  //     .post(API.Admin.CHANGE_TOKEN_IMAGE, formData)
  //     .then((response) => {
  //       if (response.data.status == 200) {
  //         setTimeout(() => {
  //           toast.success(response.data.message);
  //           getChainList();
  //         }, 700);
  //         e.target.value = ""
  //       }
  //     })
  //     .catch((error) => {});
  // };
  const MAX_IMAGE_SIZE_MB = 5;

  const handleImageUpload = async (e, id) => {
    const image = e.target.files[0];

    setLoader(true);
    if (
      image &&
      image.type.startsWith("image/") &&
      image.size <= MAX_IMAGE_SIZE_MB * 1024 * 1024
    ) {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("registerDocument", e.target.files[0]);

      await DataService(token)
        .post(API.Admin.CHANGE_TOKEN_IMAGE, formData)
        .then((response) => {
          if (response.data.status === 200) {
            setTimeout(() => {
              toast.success(response.data.message);
              getChainList();
            }, 700);
            e.target.value = "";
          }
        })
        .catch((error) => {
          toast.error("Unsupported file type. Please upload an image file");
          e.target.value = "";
          setLoader(false);
        });
    } else {
      console.log(e.target.files, 103);
      setTimeout(() => {
        setLoader(false);

        if (!image.type.startsWith("image/")) {
          toast.error("Unsupported file type. Please upload an image file");
        } else {
          toast.error(`File size exceeds the limit of ${MAX_IMAGE_SIZE_MB}MB`);
        }
      }, 700);

      e.target.value = "";
    }
    console.log(e.target.files, 117);
  };

  const handleSearch = (event) => {
    const result = chainList.filter((item) => {
      console.log(item, 136);

      setSearchVal(event?.target?.value);
      return item?.name
        ?.toLowerCase()
        ?.toString()
        ?.includes(event?.target?.value?.toLowerCase());
    });
    setFilterData(result);
    setCount(result?.length);
    setPage(0);
  };

  const goToTokenDetails = (data) => {
    navigate(`/admin/chainlist/${data.name}`, { state: { data } });
  };
  const userDisable = async (id) => {
    setLoader(true);
    const formData = new URLSearchParams();
    formData.append("id", id);
    await DataService(token)
      .post(API.Admin.CATEGORY_ACTIVE_DEACTIVE, formData)
      .then((response) => {
        if (response.data.status == 200) {
          setTimeout(() => {
            toast.success(response.data.message);
            getChainList();
          }, 700);
        }
      })
      .catch((error) => {});
  };
  return (
    <div style={{ position: "relative" }}>
      {loader && (
        <div>
          <Index.Box className="loader-main">
            <img src={Index.Png.loader} className="loader-img" />
          </Index.Box>
        </div>
      )}
      <div className={loader ? "loader-overlay" : ""}>
        <Index.Box className="dashboard-content bg-white add-user-container transaction-wrap">
          <Index.Box className="user-head-title">
            <Index.Typography
              className="admin-page-title user-list-page-title res-admin-page-title-set"
              component="h2"
              variant="h2"
            >
              Token Category List
            </Index.Typography>

            <Index.Box className="preasale-search">
              <Index.Box className="user-search-main cus-search">
                <Index.Box className="user-search-box">
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      value={searchVal}
                      placeholder="Search..."
                      onChange={(e) => handleSearch(e)}
                    />

                    <img
                      src={Index.Svg.search}
                      className="search-grey-img"
                      alt="search grey img"
                    ></img>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-dashboard-list-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="">
                    <Index.Box className="page-table-main table-design-main transaction-table-main ">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 650 }}
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                No.
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                Name
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                Action
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>

                          <Index.TableBody className="table-body">
                            {/* {filterData.length > 0 ? (
                            filterData?.map((row, index) => { */}
                            {filterData ? (
                              filterData
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((row, index) => {
                                  const currentIndex =
                                    page * rowsPerPage + index + 1;
                                  return (
                                    <Index.TableRow
                                      key={row?._id}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                      >
                                        {currentIndex}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                      >
                                        <Index.Box className="token-image">
                                          <Index.Box className="profile-header">
                                            <Index.Box className="profile-input-box">
                                              <label
                                                htmlFor={`btn-upload-${row._id}`}
                                              >
                                                <img
                                                  src={
                                                    row?.image
                                                      ? //  `http://localhost:5050/api/uploads/${row?.image}`
                                                        `${process.env.REACT_APP_API_BASE_IMAGE_URL}${row.image}`
                                                      : Index.Png.profile
                                                  }
                                                  alt="Image Preview"
                                                  className="profile-img"
                                                />

                                                <input
                                                  id={`btn-upload-${row._id}`}
                                                  name={`image-${row._id}`}
                                                  className="profile-upload-btn"
                                                  type="file"
                                                  accept="image/*"
                                                  hidden
                                                  onChange={(e) =>
                                                    handleImageUpload(
                                                      e,
                                                      row?._id
                                                    )
                                                  }
                                                  // onChange={(e) => {
                                                  //   setFieldValue(
                                                  //     "profile_image",
                                                  //     e.target.files[0]
                                                  //   );
                                                  // }}
                                                />
                                                <Index.Box className="profile-edit-main">
                                                  <Index.Tooltip
                                                    title="Edit"
                                                    placement="right"
                                                  >
                                                    <Index.Box className="edit-div">
                                                      {" "}
                                                      <img
                                                        className="profile-edit-img"
                                                        src={Index.Png.edit}
                                                        alt=""
                                                      />
                                                    </Index.Box>
                                                  </Index.Tooltip>
                                                </Index.Box>
                                              </label>
                                            </Index.Box>
                                          </Index.Box>
                                          <Index.Box className="token-name">
                                            {row?.name}
                                          </Index.Box>
                                        </Index.Box>
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="right"
                                      >
                                        {/* {row?.amount ? val.toFixed(2) : "-"} */}
                                        <Index.IconButton>
                                          <Index.FormControlLabel
                                            control={
                                              <Index.IOSSwitch
                                                checked={
                                                  row?.is_active === true
                                                }
                                                sx={{ m: 1 }}
                                                className="MuiSwitch-track-active"
                                                onClick={() => {
                                                  userDisable(row?._id);
                                                }}
                                              />
                                            }
                                            className="switch-lable"
                                          />
                                        </Index.IconButton>
                                        <Index.IconButton
                                          onClick={() => goToTokenDetails(row)}
                                        >
                                          <Index.Visibility />
                                        </Index.IconButton>
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  );
                                })
                            ) : (
                              <Index.TableRow>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                  colSpan={7}
                                >
                                  No Record found
                                </Index.TableCell>
                              </Index.TableRow>
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>{" "}
                  <Index.Box className="pagination-main">
                    <Index.TablePagination
                      component="div"
                      page={page}
                      // count={filterData?.length}
                      count={count}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[15, 25, 50]}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelDisplayedRows={(page) =>
                        `Records ${page.from} to ${
                          page.to === -1 ? page.count : page.to
                        } of ${page.count}`
                      }
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </div>
    </div>
  );
}
